body {
  background: #050541;
  color: #ffffff;
  font-family: "Rubik", sans-serif;
}

.web3modal-modal-lightbox {
  z-index: 999 !important;
}

header {
  position: absolute;
  width: 100%;
  z-index: 999;
  left: 0;
  top: 0;
}

.header_container {
  display: flex;
  padding-top: 30px;
  padding-bottom: 30px;
}

.menu_primary {
  width: 100%;
}

.menu_primary ul {
  display: flex;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
}

.menu_primary ul li {
  list-style: none;
}

.menu_primary ul li a {
  color: #fff;
  font-size: 13px;
  cursor: pointer;
  text-decoration: none;
  padding: 15px;
  margin-left: 15px;
  font-weight: 400;
  display: block;
  z-index: 100;
}

.main_banner {
  display: flex;
  min-height: 650px;
  height: 100vh;
  align-items: center;
  justify-content: center;
  padding: 70px 0;
  position: relative;
  margin-top: 50px;
}

.pink_blur {
  background: linear-gradient(180deg, #7c2ae8 0%, #ee219d 100%);
  filter: blur(200px);
  position: absolute;
  width: 60%;
  height: 100%;
  right: -10%;
  top: -10%;
  border-radius: 100%;
  z-index: 3;
}

.blur_line2 {
  right: 0;
  position: absolute;
  background: linear-gradient(
    180.08deg,
    rgba(30, 8, 99, 0) 13.19%,
    #2d0576 66.84%
  );
  transform: rotate(-120deg);
  width: 120.13px;
  height: 130%;
  z-index: 1;
  right: 20%;
  bottom: -48%;
}

.blur_line1 {
  position: absolute;
  background: linear-gradient(
    180.09deg,
    rgba(30, 8, 99, 0) 31.57%,
    #2d0576 71.29%
  );
  transform: rotate(-120deg);
  width: 120.13px;
  height: 130%;
  z-index: 1;
  right: 50%;
  bottom: -40%;
}

.main_banner .container {
  position: relative;
  z-index: 9;
}

.main_banner h1 {
  font-family: "Poppins", sans-serif;
  font-size: 60px;
  line-height: 1.5;
}

.main_banner_content p {
  font-size: 18px;
  line-height: 26px;
  position: relative;
  padding-left: 40px;
  margin-bottom: 0;
  margin-top: 20px;
}

.main_banner_content p b {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 0px;
  top: 0px;
  background: linear-gradient(180deg, #7c2ae8 0%, #ee219d 100%);
  border-radius: 100%;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
}

.disabled {
  background-color: grey !important;
  border-color: grey !important;
  cursor: disabled;
}

.btn-light {
  padding: 5px 15px;
  border-radius: 30px;
  cursor: none;
}

.timer_card {
  padding: 50px 0px;
  text-align: center;
  background: linear-gradient(180deg, #15154b 0%, rgba(13, 13, 43, 0) 100%);
  border-radius: 30px;
  border: 3px solid #32327e;
  height: 100%;
}

.progress {
  background-color: grey;
}

.timer_card h2 {
  letter-spacing: 0.01em;
  background: linear-gradient(180deg, #b278ff 0%, #ee219d 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-size: 60px;
  line-height: 1.5;
}

.timer_card h5 {
  font-size: 26px;
  text-transform: uppercase;
  margin: 0;
}

.timer {
  display: flex;
  align-items: center;
  padding: 10px 40px;
  justify-content: center;
}

.time_box > span {
  border: 2px solid #cab1f8;
  border-radius: 6px;
  display: inline-block;
  padding: 10px;
  font-size: 22px;
  margin: 3px;
  font-weight: 500;
  line-height: 1.2;
  min-width: 40px;
}

.time_box {
  position: relative;
  padding-right: 20px;
}

.time_box:after {
  content: ":";
  position: absolute;
  right: 6px;
  top: 3px;
  font-size: 30px;
  color: #ee219d;
}

.time_box:last-child:after {
  display: none !important;
}

.time_box p {
  margin: 0;
  margin-top: 10px;
}

.sec_title {
  text-align: center;
  font-size: 40px;
  line-height: 1.5;
  margin-bottom: 50px;
}

.pool_card {
  background: #291086;
  border-radius: 16px;
  padding: 30px 20px;
  text-align: center;
  margin-bottom: 70px;
}

.pool_icon {
  display: inline-block;
  background: linear-gradient(180deg, #7c2ae8 0%, #ee219d 100%);
  width: 80px;
  height: 80px;
  line-height: 80px;
  border-radius: 100%;
  margin-bottom: 50px;
}

.tickets {
  background: #35068c;
  border-radius: 16px;
  padding: 50px 30px;
}

.tickets ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.tickets ul li {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.tickets ul li:last-child {
  margin: 0;
}

.ticket_icon {
  width: calc(60% + 50px);
  position: relative;
}

.ticket_icon_box {
  display: inline-block;
  background: linear-gradient(180deg, #7c2ae8 0%, #ee219d 100%);
  width: 40px;
  height: 40px;
  border-radius: 100%;
  line-height: 37px;
  text-align: center;
  margin-right: 15px;
  vertical-align: middle;
}

.ticket_icon_box svg {
  width: 24px;
  height: 24px;
}

.cm_accordion .accordion-item {
  background: transparent;
  border: 0;
  margin-bottom: 30px;
}

.cm_accordion .accordion-button {
  background: linear-gradient(
    180deg,
    #35068c 0%,
    rgba(53, 6, 140, 0) 100%
  ) !important;
  border-radius: 8px;
  color: #fff !important;
  font-size: 20px;
  line-height: 26px;
  border: 0 !important;
  box-shadow: none !important;
  padding: 25px 20px;
}

.cm_accordion .accordion-body {
  background: #35078b;
  color: #fff;
  border-radius: 0px 0px 8px 8px;
}

.cm_accordion .accordion-button::after {
  width: 18px;
  height: 2px;
  background: #fff;
  border-radius: 1px;
  transform: none !important;
}

.cm_accordion .accordion-button.collapsed::before {
  content: "";
  height: 18px;
  width: 2px;
  background: #fff;
  border-radius: 1px;
  position: absolute;
  right: 28px;
  top: 50%;
  transform: translateY(-50%);
}

.cm_section.subscribe_us {
  background: linear-gradient(180deg, #2b0279 0%, rgba(43, 2, 121, 0) 100%);
  padding: 88px 0;
}

.subscribe_us_container {
  background: #7c2ae8;
  padding: 48px;
  border-radius: 16px;
  position: relative;
}

.form-control {
  border: 0;
  background-color: transparent;
  border-radius: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  outline: none !important;
  color: #fff;
}

.form-control:focus {
  color: #fff;
  background-color: transparent;
  outline: 0;
  box-shadow: none !important;
}

.subscribe_us_container .form-control {
  margin-right: 40px;
}

.subscribe_bg_1 {
  position: absolute;
  left: 22px;
  top: 0;
}

.subscribe_bg_2 {
  position: absolute;
  right: 18px;
  bottom: 0;
}

footer {
  background: linear-gradient(180deg, #190145 0%, #220061 100%);
  padding-top: 90px;
}

.cm_copyright {
  background: linear-gradient(180deg, #1d0150 0%, rgba(43, 2, 121, 0) 100%);
  padding: 30px 15px;
  margin-top: 120px;
}

.cm_copyright p {
  margin: 0;
}

.social_icons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.social_icons p {
  font-size: 20px;
  margin: 0;
  line-height: 1;
  margin-right: 30px;
  text-transform: uppercase;
  font-weight: 600;
}

.social_icons div a svg {
  height: 26px;
}

.social_icons div a {
  display: inline-block;
  margin-left: 15px;
}

footer h3 {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 20px;
}

.footer_menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer_menu > div {
  width: 50%;
}

.footer_menu a {
  display: block;
  text-decoration: none;
  color: #fff;
  margin-bottom: 15px;
}

.pay_options img {
  margin-right: 20px;
}

.pay_options img:last-child {
  margin-right: 0;
}

.pay_options {
  margin-top: 34px;
}

.cm_accordion {
  padding: 100px 0;
}

.cm_pool_section {
  padding-bottom: 100px;
  position: relative;
}

.cm_pool_blur {
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(
    180deg,
    rgba(42, 61, 232, 0.5) 0%,
    rgba(43, 7, 110, 0.5) 100%
  );
  filter: blur(100px);
  left: 0;
  top: -5%;
}

.cm_pool_section .container {
  position: relative;
  z-index: 9;
}

.page_wrapper {
  overflow: hidden;
}

.btn-primary {
  background: #7c2ae8;
  border-color: #7c2ae8;
  border-radius: 32px;
  padding: 15px 30px;
  font-size: 14px;
  font-weight: 500;
}

.header_btn a {
  background: #7c2ae8;
  border: 1px solid #7c2ae8;
  border-radius: 32px;
  padding: 15px 30px !important;
  font-size: 14px;
  font-weight: 500;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.header_btn a:hover,
.btn-primary:hover {
  background: #a51f9f;
  border: 1px solid #bb56b6;
}

.subscribe_us_container .row {
  position: relative;
  z-index: 9;
}

.btn {
  cursor: pointer !important;
}

.cm_angle {
  width: 16px;
  height: 16px;
  display: inline-block;
  border-radius: 100%;
  position: relative;
  margin-right: 8px;
  vertical-align: middle;
}

.angle_up .cm_angle {
  background: rgb(143 255 190 / 10%);
}
.angle_down .cm_angle {
  background: rgb(255 118 118 / 10%);
}

.angle_up .cm_angle:after {
  content: "";
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 5px solid #8fffbe;
  position: absolute;
  left: 4px;
  top: 5px;
}

.angle_down .cm_angle:after {
  content: "";
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 5px solid #ff7676;
  position: absolute;
  left: 4px;
  top: 6px;
}

.lang_dropdown .dropdown-toggle {
  background: transparent;
  border: 0 !important;
  border-left: 1px solid rgb(242 242 242 / 30%) !important;
  border-radius: 0 !important;
  padding: 4px 21px !important;
  margin-left: 15px;
}

.lang_dropdown .dropdown-toggle:focus {
  box-shadow: none !important;
}

.lang_dropdown {
  display: flex;
  align-items: center;
}

.lang_dropdown .dropdown-menu {
  background: #da46c0;
}

.lang_dropdown a {
  padding: 10px 15px !important;
  margin-left: 0 !important;
  color: #fff;
}

.pay_options > span {
  display: inline-block;
  background: rgb(224 224 224 / 50%);
  width: 96px;
  height: 64px;
  vertical-align: top;
  margin-right: 24px;
  border-radius: 8px;
  margin-bottom: 24px;
  position: relative;
}

.pay_options > span svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: 40px;
}

.main_logo {
  align-self: center;
}

.no_hover:focus,
.no_hover:hover {
  background: #f8f9fa !important;
  border-color: #f8f9fa !important;
  box-shadow: none !important;
}

@media (max-width: 1199px) {
  .main_banner h1 {
    font-size: 40px;
  }

  .main_logo {
    visibility: hidden;
    display: none;
  }

  .main_banner_content p {
    font-size: 16px;
    padding-left: 36px;
  }

  .main_banner_content p b {
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 14px;
  }

  .timer_card h5 {
    font-size: 22px;
  }

  .timer_card h2 {
    font-size: 50px;
  }

  .time_box > span {
    padding: 8px;
    font-size: 18px;
    min-width: 34px;
  }

  .timer {
    padding: 10px 15px;
  }

  .time_box p {
    margin-top: 7px;
  }

  .sec_title {
    font-size: 34px;
    margin-bottom: 40px;
  }

  .pool_icon {
    width: 70px;
    height: 70px;
    line-height: 70px;
    margin-bottom: 40px;
  }

  .pool_icon svg {
    height: 36px;
  }

  .pool_card h3 {
    font-size: 22px;
  }

  footer h2 {
    font-size: 24px;
  }

  .pay_options img {
    height: 50px;
  }

  .social_icons p {
    font-size: 18px;
  }
}

@media (min-width: 992px) {
  .show_mobile,
  .menu_icon {
    display: none !important;
  }
}

@media (max-width: 991px) {
  li.lang_dropdown.hide_mobile,
  .hide_on_mobile,
  .blur_line1,
  .blur_line2 {
    display: none !important;
  }

  .page_wrapper {
    padding-top: 110px;
  }

  .header_container {
    position: relative;
    justify-content: space-between;
    align-items: center;
    padding-right: 50px;
  }

  .lang_dropdown .dropdown-toggle {
    border: 0 !important;
    margin-left: 0;
  }

  .menu_primary {
    position: absolute;
    width: 100%;
    left: 0;
    top: 80px;
    background: #3f3356;
    padding: 25px 0;
    border-radius: 16px;
    transition: all 0.3s;
    transform: translateY(-500px);
  }

  .menu_open .menu_primary {
    transform: translateY(0px);
  }

  .menu_primary ul {
    display: block;
  }

  .menu_primary ul li a {
    padding: 12px 30px;
    margin-left: 0;
    font-size: 18px;
  }

  .header_btn {
    text-align: center;
  }

  .header_btn a {
    display: inline-block !important;
  }

  .menu_icon {
    /* position: relative;
    right: 15px;
    top: 30px; */
    cursor: pointer;
  }

  .mob_view {
    text-align: center;
    margin-top: 50px;
  }

  .main_banner_content {
    text-align: left;
  }

  .cm_pool_section {
    margin-top: 100px;
    padding-bottom: 60px !important;
  }

  .mob_text_center {
    text-align: center;
  }

  .tickets {
    margin-top: 30px;
  }

  .pink_blur {
    width: 100%;
    height: 400px;
    right: 0;
    top: auto;
    background: linear-gradient(180deg, #7c2ae8 0%, #ee219d 100%);
    filter: blur(159.03px);
    bottom: 0;
  }

  .footer_menu {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .pool_card {
    margin-bottom: 20px;
  }

  .social_icons {
    display: block;
    text-align: center;
  }

  .social_icons p {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .cm_copyright {
    text-align: center;
  }

  .cm_copyright .order-lg-1 {
    margin-top: 20px;
  }

  .cm_copyright {
    margin-top: 70px;
  }
}

@media (max-width: 470px) {
  .time_box > span {
    padding: 8px;
    font-size: 14px;
    min-width: 30px;
  }
}

@media (max-width: 447px) {
  .timer {
    padding: 10px 10px;
  }

  .main_banner {
    height: auto;
    padding-top: 0;
  }

  .time_box > span {
    padding: 6px;
    font-size: 11px;
    margin: 1px;
    min-width: 24px;
    font-weight: 300;
  }

  .time_box {
    padding-right: 12px;
  }

  .time_box:after {
    right: 3px;
    top: -1px;
    font-size: 22px;
  }
}

.modal-content {
  border: 0;
  background: linear-gradient(180deg, #2b0279 0%, rgba(43, 2, 121, 0) 100%);
}

.modal-dialog {
  background-color: #050541;
  border-radius: 10px;
}
.modal-footer {
  justify-content: center;
  border: none;
}
.modal-body {
  overflow-x: auto;
  max-height: 80vh;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.step-wizard {
  display: flex;
  justify-content: center;
  align-items: center;
}
.step-wizard-list {
  color: white;
  list-style-type: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.step-wizard-item {
  padding: 0 20px;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  min-width: 170px;
  position: relative;
}
.step-wizard-item + .step-wizard-item:after {
  content: "";
  position: absolute;
  left: 0;
  top: 19px;
  background: #7c2ae8;
  width: 100%;
  height: 2px;
  transform: translateX(-50%);
  z-index: -10;
}
.progress-count {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 600;
  margin: 0 auto;
  position: relative;
  z-index: 10;
  color: transparent;
}
.progress-count:after {
  content: "";
  height: 40px;
  width: 40px;
  background: #7c2ae8;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: -10;
}
.progress-count:before {
  content: "";
  height: 10px;
  width: 20px;
  border-left: 3px solid #fff;
  border-bottom: 3px solid #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -60%) rotate(-45deg);
  transform-origin: center center;
}
.progress-label {
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
}
.current-item .progress-count:before,
.current-item ~ .step-wizard-item .progress-count:before {
  display: none;
}
.current-item ~ .step-wizard-item .progress-count:after {
  height: 10px;
  width: 10px;
}
.current-item ~ .step-wizard-item .progress-label {
  opacity: 0.5;
}
.current-item .progress-count:after {
  background: #fff;
  border: 2px solid #7c2ae8;
}
.current-item .progress-count {
  color: #7c2ae8;
}

@media (max-width: 1199px) {
  .step-wizard-item {
    min-width: 100px;
  }
}
